// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import RoleService from '@services/role'

export const getRoles = createAsyncThunk('role/getRoles', async (params, { rejectWithValue }) => {
  try {
    const response = await RoleService.getRoles(params)
    return { params, data: response.data, totalPages: response.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getRoleById = createAsyncThunk('role/getRoleById', async (id) => {
  console.log('roledI', id)
  const response = await RoleService.getRoleById(id)
  console.log('response', response)
  return response
})

export const addRole = createAsyncThunk('role/addRole', async (role, { rejectWithValue }) => {
  try {
    const response = await RoleService.addRole(role)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateRole = createAsyncThunk('role/updateRole', async (role, { rejectWithValue }) => {
  try {
    const response = await RoleService.updateRole(role)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

// export const deleteRole = createAsyncThunk('role/deleteRole', async (id, { dispatch, getState }) => {
//   await RoleService.deleteRole(id)
//   await dispatch(getMember(getState()?.memberships.params))
//   return id
// })

export const roleSlice = createSlice({
  name: 'roles',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualRole: null,
    loading: true
  },
  reducers: {
    selectRole: (state, action) => {
      if (action.payload === null) {
        state.individualRole = null
      } else {
        state.individualRole = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getRoleById.fulfilled, (state, action) => {
        state.individualRole = action.payload
        state.loading = false
      })
  }
})

export default roleSlice.reducer
