// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import StationService from '@services/station'

export const getStations = createAsyncThunk('appStations/getStations', async (params) => {
  const response = await StationService.getStations(params)
  return {
    params,
    data: response.data,
    totalItems: response.itemCount
  }
})

export const getStation = createAsyncThunk('appStations/getStation', async (id) => {
  const response = await StationService.getStation(id)

  return response
})

export const addStation = createAsyncThunk('appStations/addStation', async (station, { dispatch }) => {
  await StationService.addStation(station)
  // await dispatch(getStation()
  await dispatch(getStations())
  return station
})

export const updateStation = createAsyncThunk('appStations/updateStation', async (station, { rejectWithValue }) => {
  try {
    const response = await StationService.updateStation(station._id, station)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteStation = createAsyncThunk('appStations/deleteStation', async (id, { dispatch, getState }) => {
  await StationService.deleteStation(id)
  await dispatch(getStations(getState()?.users.params))
  return id
})

export const appStationsSlice = createSlice({
  name: 'stations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedStation: null,
    loading: true
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStations.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalItems
      })
      .addCase(getStation.fulfilled, (state, action) => {
        state.selectedStation = action.payload
        state.loading = false
      })
      .addCase(getStation.pending, (state) => {
        state.loading = true
      })
  }
})

export default appStationsSlice.reducer
