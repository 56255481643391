// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const UserList = lazy(() => import('../../views/apps/user/list'))
const UserView = lazy(() => import('../../views/apps/user/view'))
const StationView = lazy(() => import('../../views/apps/charging-station/view'))
const LocationPicker = lazy(() => import('../../views/apps/charging-station/list/map'))
const AddStation = lazy(() => import('../../views/apps/charging-station/list/add-station'))
const EditStationForm = lazy(() => import('../../views/apps/charging-station/edit/index'))
const PublicUserList = lazy(() => import('../../views/apps/public-user/list'))
const PublicUserView = lazy(() => import('../../views/apps/public-user/view/index'))
const ChangePassword = lazy(() => import('../../views/apps/user/view/changePassword'))

const ChargingStationList = lazy(() => import('../../views/apps/charging-station/list'))

const Roles = lazy(() => import('../../views/role/list/index'))
const RoleAddForm = lazy(() => import('../../views/role/add/index'))
const RoleUpdateForm = lazy(() => import('../../views/role/edit/index'))
const Permissions = lazy(() => import('../../views/permission/list/index'))
const PermissionsAddForm = lazy(() => import('../../views/permission/add/index'))

const AppRoutes = [
  {
    element: <UserList />,
    path: '/apps/user/list'
  },

  {
    element: <PublicUserList />,
    path: '/apps/public-user/list'
  },
  // {
  //   path: '/apps/user/view',
  //   element: <Navigate to="/apps/user/view/1" />
  // },
  {
    element: <UserView />,
    path: '/apps/user/view/:id'
  },
  {
    element: <PublicUserView />,
    path: '/apps/public-user/view/:id'
  },
  {
    element: <Roles />,
    path: '/apps/roles'
  },
  {
    path: '/apps/roles/add',
    element: <RoleAddForm />
  },
  {
    path: '/apps/roles/edit/:id',
    element: <RoleUpdateForm />
  },
  {
    element: <Permissions />,
    path: '/apps/permissions'
  },
  {
    path: '/apps/permissions/add',
    element: <PermissionsAddForm />
  },
  {
    element: <ChargingStationList />,
    path: '/apps/charging-station/list',
    meta: {
      action: 'manage',
      resource: 'Station'
    }
  },
  {
    element: <AddStation />,
    path: '/apps/charging-station/add',
    meta: {
      action: 'manage',
      resource: 'Station'
    }
  },
  {
    element: <StationView />,
    path: '/apps/charging-station/view/:id',
    meta: {
      action: 'manage',
      resource: 'Station'
    }
  },
  {
    element: <EditStationForm />,
    path: '/apps/charging-station/edit/:id',
    meta: {
      action: 'manage',
      resource: 'Station'
    }
  },
  {
    element: <LocationPicker />,
    path: '/apps/charging-station/location-picker',
    meta: {
      action: 'manage',
      resource: 'Station'
    }
  },
  {
    element: <ChangePassword />,
    path: '/apps/change-password'
    // meta: {
    //   action: 'manage',
    //   resource: 'Station'
    // }
  }
  // {
  //   path: '/apps/charging-station/view',
  //   element: <Navigate to="/apps/charging-station/view/1" />
  // },
  // {
  //   element: <ChargingStationView />,
  //   path: '/apps/charging-station/view/:id'
  // }
]

export default AppRoutes
