import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL
const servicePrefix = `${serviceBaseUrl}/api`

const getRoles = async (params) => {
  const response = await api.get(`${servicePrefix}/role`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllRoles = async () => {
  const response = await api.get(`${servicePrefix}/role/all`)
  return response.data
}

const getRolesWithPage = async (params) => {
  const response = await api.get(`${servicePrefix}/role`, { params: stripEmptyObjects(params) })
  return response.data
}

const getRoleById = async (id) => {
  const response = await api.get(`${servicePrefix}/role/${id}`)
  return response.data
}

const addRole = async (role) => {
  const response = await api.post(`${servicePrefix}/role`, role)
  return response.data
}

const updateRole = async (role) => {
  const { id } = role
  const response = await api.patch(`${servicePrefix}/role/${id}`, role)
  return response.data
}

const deleteRole = async (id) => {
  const response = await api.delete(`${servicePrefix}/role/${id}`)
  return response.data
}

const RoleService = {
  getRoles,
  getAllRoles,
  getRoleById,
  addRole,
  getRolesWithPage,
  updateRole,
  deleteRole
}

export default RoleService
