// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import users from '@src/views/apps/user/store'
import publicUsers from '@src/views/apps/public-user/store'
import permissions from '@src/views/permission/store'
import stations from '@src/views/apps/charging-station/store'
import roles from '@src/views/role/store'

const rootReducer = {
  auth,
  users,
  publicUsers,
  stations,
  roles,
  navbar,
  layout,
  permissions
}

export default rootReducer
