// ** Icons Import
import { Circle, User, Shield, Zap, Users } from 'react-feather'

export default [
  {
    id: 'users',
    title: 'Admin Users',
    icon: <User size={20} />,
    children: [
      {
        id: 'list',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/user/list',
        resource: 'User'
      }
    ]
  },
  {
    id: 'roles-permissions',
    title: 'Roles & Permissions',
    icon: <Shield size={20} />,
    children: [
      {
        id: 'roles',
        title: 'Roles',
        icon: <Circle size={12} />,
        navLink: '/apps/roles',
        resource: 'Role'
      },
      {
        id: 'permissions',
        title: 'Permissions',
        icon: <Circle size={12} />,
        navLink: '/apps/permissions',
        resource: 'Permission'
      }
    ]
  },
  // {
  //   id: 'public-user',
  //   title: 'App Users',
  //   icon: <Users size={20} />,
  //   resource: 'Station',
  //   children: [
  //     {
  //       id: 'list',
  //       title: 'List',
  //       icon: <Circle size={12} />,
  //       navLink: '/apps/public-user/list',
  //       resource: 'User'
  //     }
  //   ]
  // },
  {
    id: 'charging-station',
    title: 'Charging Station',
    icon: <Zap size={20} />,
    resource: 'Station',
    children: [
      {
        id: 'list',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/charging-station/list',
        resource: 'Station'
      }
    ]
  }
]
