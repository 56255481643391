// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt'

const serviceBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL
const servicePrefix = `${serviceBaseUrl}/api`

const { jwt } = useJwt({
  loginEndpoint: `${servicePrefix}/auth/admin-login`,
  registerEndpoint: `${servicePrefix}/auth/admin-login`,
  refreshEndpoint: `${servicePrefix}/auth/refresh`,
  logoutEndpoint: `${servicePrefix}/auth/logout`,

  // loginEndpoint: '/jwt/login',
  // registerEndpoint: '/jwt/register',
  // refreshEndpoint: '/jwt/refresh-token',
  // logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
})

export default jwt
