// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import UserService from '@services/user'

export const getPublicData = createAsyncThunk('publicUsers/getData', async (params) => {
  const response = await UserService.getPublicUsers(params)
  return {
    params,
    data: response.data,
    totalItems: response.itemCount
  }
})

export const getPublicUser = createAsyncThunk('publicUsers/getUser', async (id) => {
  const response = await UserService.getPublicUserById(id)

  return response
})

export const verifyProfile = createAsyncThunk('publicUsers/verifyProfile', async (id) => {
  const response = await UserService.verifyProfile(id)

  return response
})

export const deleteProfile = createAsyncThunk('appUsers/deleteProfile', async (id, { dispatch, getState }) => {
  await UserService.deleteProfile(id)
  await dispatch(getPublicData(getState()?.users.params))
  return id
})

export const publicUsersSlice = createSlice({
  name: 'publicUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allPublicProfile: [],
    publicUser: null,
    loading: true
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublicData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalItems
      })
      .addCase(getPublicUser.fulfilled, (state, action) => {
        state.publicUser = action.payload
        state.loading = false
      })
      .addCase(getPublicUser.pending, (state) => {
        state.loading = true
      })
  }
})

export default publicUsersSlice.reducer
