import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL
const servicePrefix = `${serviceBaseUrl}/api`

const getUsers = async (params) => {
  const response = await api.get(`${servicePrefix}/admin-user`, { params: stripEmptyObjects(params) })
  return response.data
}

const getBpcUser = async () => {
  const response = await api.get(`${servicePrefix}/admin-user/get-bpc-user`)
  return response.data
}

const getUser = async (id) => {
  const response = await api.get(`${servicePrefix}/admin-user/${id}`)
  return response.data
}

const addUser = async (user) => {
  const response = await api.post(`${servicePrefix}/admin-user`, user)
  return response.data
}

const updateUser = async (id, user) => {
  const response = await api.patch(`${servicePrefix}/admin-user/${id}`, user)
  return response.data
}

const deactivateUser = async (userId) => {
  const response = await api.patch(`${servicePrefix}/admin-user/deactivate/${userId}`)
  return response.data
}

const changeMyPassword = async (passwordPayload, accessToken) => {
  const response = await api.patch(`${servicePrefix}/admin-user/change-password`, passwordPayload, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  return response.data
}

const deleteUser = async (id) => {
  console.log(id)
  const response = await api.delete(`${servicePrefix}/admin-user/${id}`)
  return response.data
}

const AdminUserService = {
  getUsers,
  getUser,
  addUser,
  getBpcUser,
  updateUser,
  deactivateUser,
  changeMyPassword,
  deleteUser
}

export default AdminUserService
