import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL
const servicePrefix = `${serviceBaseUrl}/api`

const getPermissions = async (params) => {
  const response = await api.get(`${servicePrefix}/permission`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllPermissions = async () => {
  const response = await api.get(`${servicePrefix}/permission/all`)
  return response.data
}

const getPermissionsWithPage = async (params) => {
  const response = await api.get(`${servicePrefix}/permission`, { params: stripEmptyObjects(params) })
  return response.data
}

const getPermission = async (id) => {
  const response = await api.get(`${servicePrefix}/permission/${id}`)
  return response.data
}

const addPermission = async (role) => {
  const response = await api.post(`${servicePrefix}/permission`, role)
  return response.data
}

const updatePermission = async (user) => {
  const { id } = user
  const response = await api.patch(`${servicePrefix}/permission/${id}`, role)
  return response.data
}

const deletePermission = async (id) => {
  const response = await api.delete(`${servicePrefix}/permission/${id}`)
  return response.data
}

const PermissionService = {
  getPermissions,
  getAllPermissions,
  getPermissionsWithPage,
  getPermission,
  addPermission,
  updatePermission,
  deletePermission
}

export default PermissionService
