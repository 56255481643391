// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import AdminUserService from '@services/admin-user'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  // const response = await axios.get('/api/users/list/all-data')
  const response = await AdminUserService.getUsers()

  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async (params) => {
  const response = await AdminUserService.getUsers(params)
  return {
    params,
    data: response.data,
    totalItems: response.itemCount
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async (id) => {
  const response = await AdminUserService.getUser(id)

  return response
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await AdminUserService.addUser(user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async (user, { rejectWithValue }) => {
  try {
    const response = await AdminUserService.updateUser(user._id, user)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deactivateUser = createAsyncThunk('appUsers/deactivateUser', async (id) => {
  const response = await AdminUserService.deactivateUser(id)
  return response
})

export const changeUserPassword = createAsyncThunk('appUsers/changePassword', async (passwordPayload, { rejectWithValue }) => {
  try {
    const response = await AdminUserService.changeMyPassword(passwordPayload, passwordPayload.token)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await AdminUserService.deleteUser(id)
  await dispatch(getUser(getState()?.users.params))
  return id
})

export const appUsersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: true
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalItems
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.loading = false
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true
      })
  }
})

export default appUsersSlice.reducer
