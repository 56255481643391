import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL
const servicePrefix = `${serviceBaseUrl}/api`

const getStations = async (params) => {
  const response = await api.get(`${servicePrefix}/station`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllStations = async () => {
  const response = await api.get(`${servicePrefix}/station/all`)
  return response.data
}

const getStation = async (id) => {
  const response = await api.get(`${servicePrefix}/station/${id}`)
  return response.data
}

const addStation = async (station) => {
  const response = await api.post(`${servicePrefix}/station`, station)
  return response.data
}

const updateStation = async (id, station) => {
  const response = await api.patch(`${servicePrefix}/station/${id}`, station)
  return response.data
}

const deleteStation = async (id) => {
  const response = await api.delete(`${servicePrefix}/station/${id}`)
  return response.data
}

const StationService = {
  getStations,
  getAllStations,
  getStation,
  addStation,
  updateStation,
  deleteStation
}

export default StationService
