import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL
const servicePrefix = `${serviceBaseUrl}/api`

const getUsers = async (params) => {
  const response = await api.get(`${servicePrefix}/user`, { params: stripEmptyObjects(params) })
  return response.data
}

const getUser = async (id) => {
  const response = await api.get(`${servicePrefix}/user/${id}`)
  return response.data
}

const addUser = async (user) => {
  const response = await api.post(`${servicePrefix}/user/admin`, user)
  return response.data
}

const updateUser = async (id, user) => {
  const response = await api.patch(`${servicePrefix}/user/${id}`, user)
  return response.data
}

const deactivateUser = async (userId) => {
  const response = await api.patch(`${servicePrefix}/user/deactivate/${userId}`)
  return response.data
}

const getBpcUser = async () => {
  const response = await api.get(`${servicePrefix}/user/get-user-with-bpc-role`)
  return response.data
}

const getPublicUsers = async (params) => {
  console.log('i am here')
  const response = await api.get(`${servicePrefix}/user/public-users`, { params: stripEmptyObjects(params) })
  return response.data
}

const getPublicUserById = async (id) => {
  const response = await api.get(`${servicePrefix}/user/public-profile/${id}`)
  return response.data
}

const verifyProfile = async (id) => {
  const response = await api.patch(`${servicePrefix}/profile/verify-profile/${id}`)
  return response.data
}

const changeMyPassword = async (passwordPayload) => {
  const response = await api.patch(`${servicePrefix}/user/change-password/`, passwordPayload)
  return response.data
}

const deleteUser = async (id) => {
  console.log(id)
  const response = await api.delete(`${servicePrefix}/user/${id}`)
  return response.data
}

const deleteProfile = async (id) => {
  const response = await api.delete(`${servicePrefix}/profile/${id}`)
  return response.data
}

const forgotPaaword = async (mobileNo) => {
  const response = await api.post(`${servicePrefix}/auth/forgot-password/${mobileNo}`)
  return response.data
}

const verifyOto = async (otp, accessToken) => {
  const response = await api.get(`${servicePrefix}/auth/validate-otp/${otp}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  return response.data
}

const resetPassword = async (data, accessToken) => {
  const response = await api.patch(`${servicePrefix}/auth/reset-password`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  return response.data
}

const UserService = {
  getUsers,
  getUser,
  addUser,
  updateUser,
  deactivateUser,
  getBpcUser,
  getPublicUsers,
  getPublicUserById,
  verifyProfile,
  changeMyPassword,
  deleteUser,
  deleteProfile,
  forgotPaaword,
  verifyOto,
  resetPassword
  //   getUsersWithRoles
}

export default UserService
